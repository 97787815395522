import { useEffect } from "react";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Layout } from "@components/Layout/index";
import { getHub, getGlobalConfig, getModalSettings } from "@lib/api/calls";
import { Render } from "@components/sections";
import { trackEvent } from "@lib/track-event";
import * as redisService from "@services/redis";

export default function Index({
  config,
  preview,
  title = "Home",
  seo,
  content,
  modal,
  subscribed,
  allProducts,
}) {
  const { data: session } = useSession();
  const router = useRouter();
  const isFrench = router.locale === "fr";
  const contentFr = content.filter((block) => block._type !== "editorialList");

  useEffect(() => {
    trackEvent("view_page", {
      pageTitle: title,
      pageCategory: "Home Page",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    const hasSignout = new URLSearchParams(window.location.search).has("signout");
    async function logout() {
      const data = await signOut({ redirect: false, callbackUrl: "/" });
      Cookies.remove("kotn_logged_in");
      router.push(data.url);
    }
    if (hasSignout && session) {
      logout();
    }
  }, [session]);

  return (
    <Layout
      config={config}
      title={title}
      seo={seo}
      subscribed={subscribed}
      isPreview={preview}
      modal={modal}
      pageFound={true}
    >
      {content && <Render sections={isFrench ? contentFr : content} allProducts={allProducts} />}
    </Layout>
  );
}

export async function getStaticProps({ preview = false, locale }) {
  const config = await getGlobalConfig(locale, preview);
  const modal = await getModalSettings(locale, preview);
  const homePage = config?.frontpage?.slug?.current
    ? await getHub(config.frontpage.slug.current, locale, preview)
    : null;

  let allProducts = [];
  try {
    allProducts = await redisService.getProductList(homePage?.blocks);
  } catch (error) {
    allProducts = [];
    console.error(error.stack || error);
  }
  const seo = {
    seoTitle:
      homePage?.meta?.seoTitle ||
      "Kotn · Sustainable Clothing & Home Decor. Quality, Comfort, Ethics.",
    seoDescription:
      config?.description ||
      homePage?.meta?.seoDescription ||
      "Timeless everyday essentials, ethically crafted from the finest natural fibres. Certfied B Corp, funding schools with every purchase. Free shipping on orders $100+. ",
    openGraphImage: homePage?.meta?.openGraphImage || null,
    twitterCard: homePage?.meta?.seoTwitterCardStyle || "summary_large_image",
  };

  return {
    props: {
      preview,
      title: config?.title || homePage?.title || "",
      seo,
      content: homePage?.blocks || null,
      modal,
      config,
      allProducts: allProducts,
      ...(await serverSideTranslations(locale, [
        "common",
        "navbar",
        "footer",
        "productcard",
        "cart",
        "recommendations",
      ])),
    },
    revalidate: 60,
  };
}
